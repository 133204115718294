import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { pdfjs } from 'react-pdf';
import { font } from "./font";
import { renderAsync } from "docx-preview";
import html2canvas from 'html2canvas';

// eslint-disable-next-line import/no-anonymous-default-export
export default async (pages, attachments, signature, t) => {

    return new Promise<any>((resolve) => {

        const doc: any = new jsPDF('p', 'pt', 'a4', true);

        doc.addFileToVFS("Roboto-normal.ttf", font);
        doc.addFont("Roboto-normal.ttf", "RobotoRegular", "normal");
        doc.setFont("RobotoRegular");

        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        
        const containerElement = document.createElement("div")
        containerElement.id = "containerElement"

        pages.forEach(page => {
            const element = document.getElementById(page)
            if(element) {
                containerElement.append(element.cloneNode(true))
            }
        })

        doc.html(containerElement, {
            html2canvas: {
                onclone: (renderDoc) => {
                    // https://github.com/parallax/jsPDF/issues/3178
                    // Workaround to compress images
                    let target = renderDoc.querySelector("#containerElement");
                    // target.style.fontFamily = "Roboto"
                    // console.log("target", target)
                    let images = target.querySelectorAll("img");
        
                    let targetRect = target.getBoundingClientRect();
        
                    images.forEach((img) => {
                        let rect = img.getBoundingClientRect();
                        doc.addImage(img, "JPEG", targetRect.x - rect.x, targetRect.y - rect.y, rect.width, rect.height);
                    });
                },
            },
            callback: (doc) => {   
                if ((!attachments || attachments.length === 0) && !signature) {
                    resolve(doc);
                    return;
                }
                
                const attachmentPromises = attachments.map(attachment => new Promise(async (resolveAttachment) => {
                    const attachmentPages: string[] = [];
            
                    console.log('attachment.MIMEtype: ', attachment.MIMEtype)

                    if (attachment.MIMEtype === "application/pdf") {
                        pdfjs.disableWorker = true; // due to CORS
                        const pdf = await pdfjs.getDocument(attachment.url).promise;
            
                        for (let i = 1; i <= pdf.numPages; i++)  {
                            const page = await pdf.getPage(i);
                            const canvas = document.createElement('canvas');
                            const context = canvas.getContext('2d');
                            const viewport = page.getViewport({ scale: 2 });
            
                            canvas.width = viewport.width;
                            canvas.height = viewport.height;
            
                            await page.render({ canvasContext: context, viewport: viewport }).promise;
                            attachmentPages.push(canvas.toDataURL("image/png"));
                        }
            
                        resolveAttachment(attachmentPages);
                    } else if(attachment.MIMEtype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {

                        const response = await fetch(attachment.url);
                        const blob = await response.blob();
                        
                        const container = document.createElement("div");
                        container.style.position = "absolute"; // Hide container from view
                        container.style.top = "-9999px"; // Hide container from view
                        document.body.appendChild(container);
                        
                        try {
                            await renderAsync(blob, container);
                        
                            // Get PDF page dimensions
                            const pdfWidth = doc.internal.pageSize.getWidth(); // PDF width in points
                            const pdfHeight = doc.internal.pageSize.getHeight(); // PDF height in points
                        
                            // Convert dimensions for html2canvas rendering
                            const pointToPixel = (points) => points * (96 / 72); // 96 DPI for html2canvas
                            const pixelToPoint = (pixels) => pixels * (72 / 96); // Convert back
                        
                            const canvasWidthInPixels = pointToPixel(pdfWidth); // Match PDF width in pixels
                            const scaleFactor = 2;
                        
                            // Render the canvas with html2canvas
                            const canvas = await html2canvas(container, {
                                scale: scaleFactor,
                                width: canvasWidthInPixels, // Set canvas width to match PDF width
                            });
                        
                            const renderedHeight = canvas.height; // Actual height of the rendered content in pixels
                            const renderedWidth = canvas.width; // Actual width of the rendered content in pixels
                        
                            // Compute the scaled height to preserve the aspect ratio
                            const scaledHeight = (renderedHeight / renderedWidth) * pdfWidth;
                        
                            console.log('Rendered Canvas Dimensions:', renderedWidth, renderedHeight);
                            console.log('PDF Dimensions (points):', pdfWidth, pdfHeight);
                            console.log('Scaled Height (points):', scaledHeight);
                        
                            // If the scaled height fits within one page, add it directly
                            if (scaledHeight <= pdfHeight) {
                                doc.addPage();
                                doc.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, pdfWidth / scaleFactor, scaledHeight / scaleFactor);
                            } else {
                                // Handle multi-page splitting
                                const sliceHeightInPixels = pdfHeight * (96 / 72) * scaleFactor; // Convert PDF height to pixels and apply scale
                                const pagesNeeded = Math.ceil(renderedHeight / sliceHeightInPixels);
                        
                                console.log('Pages needed:', pagesNeeded);
                        
                                for (let i = 0; i < pagesNeeded; i++) {
                                    const pageCanvas = document.createElement("canvas");
                                    const pageContext:any = pageCanvas.getContext("2d");
                        
                                    // Set canvas dimensions for the slice (adjusting for scale)
                                    pageCanvas.width = renderedWidth;
                                    pageCanvas.height = sliceHeightInPixels;
                        
                                    // Draw the current slice of the original canvas
                                    pageContext.drawImage(
                                        canvas,
                                        0, i * sliceHeightInPixels, // Source x, y (top-left corner of slice)
                                        renderedWidth, sliceHeightInPixels, // Source width, height (slice size)
                                        0, 0, // Destination x, y (top-left corner of new canvas)
                                        renderedWidth, sliceHeightInPixels // Destination width, height
                                    );
                        
                                    const pageImageData = pageCanvas.toDataURL("image/png");
                        
                                    // Add the slice as a new page in the PDF
                                    doc.addPage();
                                    doc.addImage(
                                        pageImageData,
                                        "PNG",
                                        0, 0,
                                        pdfWidth, // Width in points remains the same
                                        pixelToPoint(sliceHeightInPixels) / scaleFactor // Scale the height back to points (divide by scaleFactor)
                                    );
                                }
                            }
                        } catch (error) {
                            console.error("Error rendering .docx file:", error);
                            resolveAttachment(attachmentPages.length ? attachmentPages : []);
                        } finally {
                            document.body.removeChild(container); // Clean up the container
                            resolveAttachment(attachmentPages.length ? attachmentPages : []);
                        }

                    } else {
                        const img = new Image();
                        img.crossOrigin = "anonymous";
                        img.src = attachment.url;

                        img.onload = function() {
                            const canvas = document.createElement('canvas');
                            canvas.width = img.width;
                            canvas.height = img.height;
            
                            const context = canvas.getContext('2d');

                            if(context) {
                                context.drawImage(img, 0, 0);
                            }
            
                            attachmentPages.push(canvas.toDataURL('image/png'));
                            resolveAttachment(attachmentPages);
                        };
                    }
                }));
            
                // Add Signature
                const signaturePromise = new Promise((resolveSignature) => {
                    if (signature && signature.url) {
                        const img = new Image();
                        img.crossOrigin = "anonymous";
                        img.src = signature.url;
            
                        img.onload = function() {
                            const canvas = document.createElement('canvas');
                            canvas.width = img.width;
                            canvas.height = img.height;
            
                            const context = canvas.getContext('2d');
                            if(context) {
                                context.drawImage(img, 0, 0);
                            }
            
                            resolveSignature(canvas.toDataURL('image/png'));
                        };
                    } else {
                        resolveSignature(null);
                    }
                });
            
                doc.setTextColor(0, 0, 0);
            
                if (attachments && attachments.length > 0) {
                    doc.addPage();
                    let y = 30;
                    
                    doc.setFontSize(18);
                    doc.text(t("Attachments"), 15, y);
                    y += 30;
            
                    doc.setFontSize(12);

                    doc.autoTable({ 
                        startY: y,
                        columnStyles: {
                            0: { cellWidth: 20 },
                            1: { cellWidth: 170 },
                            2: { cellWidth: 325 }
                        },
                        head: [[
                            "#",
                            t("Title"),
                            t("Text"),
                        ]],
                        styles: {
                            font: "RobotoRegular", 
                        },
                        body: attachments.map((attachment, idx) => {
                            return [
                                idx + 1,
                                attachment.Title,
                                attachment.Text
                            ];
                        })
                    });
                }
            
                Promise.all([...attachmentPromises, signaturePromise]).then((results) => {
                    console.log('results', results)
                    const attachmentPages = results.slice(0, -1);
                    const signatureImage = results[results.length - 1];
            
                    attachmentPages.forEach((pages, idx) => {
                        pages.forEach((page, pageIdx) => {
                            doc.addPage();
                            const imgProps = doc.getImageProperties(page);
                            const pdfWidth = doc.internal.pageSize.getWidth();
                            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                            doc.setFontSize(12);
                            doc.text(`${t("Attachment")} #${idx + 1}${pages.length > 1 ? ` (${t("Page")} ${pageIdx + 1} ${t("of")} ${pages.length})` : ""}`, 15, 15);
                            doc.addImage(page, 'PNG', 0, 20, pdfWidth, pdfHeight - 20, "", 'FAST');
                        });
                    });
            
                    if (signatureImage) {
                        doc.addPage();
                        doc.setFontSize(24);
                        doc.text("Signatur", 15, 30)
                        const imgProps = doc.getImageProperties(signatureImage);
                        const pdfWidth = doc.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        doc.setFontSize(18);
                        doc.addImage(signatureImage, 'PNG', (pdfWidth - imgProps.width / 2) / 2, 60, imgProps.width / 2, imgProps.height / 2);
                        doc.setFontSize(14);

                        let lineSpacing = 160;

                        if(signature.Name) {
                            doc.text(signature.Name, 15, lineSpacing);
                            lineSpacing += 20;
                        }
                        if(signature.Comment) {
                            doc.text(signature.Comment, 15, lineSpacing);
                            lineSpacing += 20;
                        }
                       
                        doc.text(signature.created_at, 15, lineSpacing);
                    }
            
                    resolve(doc);
                });
            }
            
        })
        
        
    })
}
