import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"
import {useSelector} from "react-redux"
import useDispatch from "../utils/useDispatch"
import { loadUseOfferLink, sendOfferLinkAnswer, loadGadgetQuotationSendingUrl, loadTenantMigrationStatusByDBName } from "../store/actions/app"
import { useTranslation } from "react-i18next"
import DisplayOffer from "./DisplayOffer"
import calculateOfferRowTotals from "./calculateOfferRowTotals"
import sum from "../utils/sum"
import '../App.css';
import { Button, FormControl, FormGroup, Radio } from "react-bootstrap"
import styled from "styled-components"
import Card from "../components/Card"
import SignatureCanvas from 'react-signature-canvas'
import useCheckMobileScreen from "../utils/useCheckMobileScreen"
import DocumentViewer from "../components/DocumentViewer"
import generateOfferPdf from "../pdf/generateOfferPdf"
import { isEqual } from "../utils/compareValues"

const ErrorPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`

const OfferLinkView = ({match}) => {
    const {tenant, email, linkToken} = match.params
    const offerLinkResponse = useSelector(store => store.app.offerLinkResponse)
    const sendingOfferLinkAnswer = useSelector(store => store.app.sendingOfferLinkAnswer)
    const loadingOfferLinkResponse = useSelector(store => store.app.loadingOfferLinkResponse)
    const loadingTenantMigrationStatus = useSelector(store => store.app.loadingTenantMigrationStatus)
    const loadingGadgetQuotationSendingUrl = useSelector(store => store.app.loadingGadgetQuotationSendingUrl)
    const dispatch = useDispatch()
    const [t] = useTranslation()
    const [answer, setAnswer] = useState("")
    const [answerHasBeenSubmitted, setAnswerHasBeenSubmitted] = useState(false)
    const signaturePad: any = useRef()
    const [signatureName, setSignatureName] = useState("")
    const [comment, setComment] = useState("")
    const [signaturePadIsEmpty, setSignaturePadIsEmpty] = useState(true)
    const isMobileScreen = useCheckMobileScreen()
    const isSuperSmallMobileScreen = useCheckMobileScreen(400)
    const [pages, setPages] = useState([])
    const [submittingAnswer, setSubmittingAnswer] = useState(false)

    useEffect(() => {

        if (email && linkToken && tenant) {
            dispatch(loadUseOfferLink(tenant, email, linkToken)).then(res => {

                const offerId = res.offer.id;

                dispatch(loadTenantMigrationStatusByDBName(tenant))
                .then(res => {
    
                    const migrationStatus = res.tenant_migration_status;
    
                    if(migrationStatus === 'successful') {

                        dispatch(loadGadgetQuotationSendingUrl(tenant, offerId)).then(res => {
                            const url = res.data.url;
                            window.location.href = url;
    
                            return;
                        });
                        
                    }
                })
            });
        };
    }, [tenant, email, linkToken, dispatch]);

    if(!offerLinkResponse || loadingOfferLinkResponse || loadingTenantMigrationStatus || loadingGadgetQuotationSendingUrl) {
        return <div>
            <div className="loader-icon"/>
        </div>
    }

    if(offerLinkResponse.access === false) {
        return <ErrorPage>
            <Card style={{padding: "2em"}}>
                <i style={{marginRight:"1em"}} className="fa fa-unlink"/> {t("TheQuotationLinkIsUsed")}
            </Card>
        </ErrorPage>
    }

    if(answerHasBeenSubmitted === true) {
        return <ErrorPage>
            <Card style={{padding: "2em"}}>
                <div>
                    <b>{t("YourAnswerHasBeenSubmitted")}!</b>
                </div>
                <div>
                    {t("YouCanCloseThisWindowNow")}
                </div>
            </Card>
        </ErrorPage>
    }

    const companyLogo = offerLinkResponse.companyLogo
    const offer = offerLinkResponse.offer
    const tenantName = offerLinkResponse.tenantName

    const loadedRows = offer.rows.map(row => {
                
        const { TotalDeductionAmount, PriceWithVAT } = calculateOfferRowTotals(row.Price, row.Quantity, row.VAT, row.Discount, row.HouseWorkDeductionTypeDeduction);

        return {
            ...row,
            Show: row.Show === "true",
            PriceWithVAT,
            TotalDeductionAmount,
            VAT: row.VAT ? +row.VAT : row.VAT,
            UnitID: row.UnitID ? +row.UnitID : row.UnitID
        }
    })

    const loadedPaymentPlanElements = offer.payment_plan_elements

    const loadedOfferInput = {
        ExpireDate: offer.ExpireDate || "",
        OfferDate: offer.OfferDate || "",
        DeliveryDate: offer.DeliveryDate || "",
        OurReference: offer.OurReference || "",
        CustomerID: offer.CustomerID,
        CustomerName: offer.CustomerName,
        CustomerType: offer.CustomerType,
        CustomerObjectID: offer.CustomerObjectID,
        InternalComment: offer.InternalComment,
        VAT: offer.VAT,
        MaxDeductionAmount: offer.MaxDeductionAmount,
        Total: offer.Total,
        NetTotal: offer.NetTotal,
        TotalVAT: offer.TotalVAT,
        TotalDeductionAmount: sum(loadedRows, "TotalDeductionAmount"),
        OfferRowsHeadline: offer.OfferRowsHeadline,
        ShowOfferRows: offer.ShowOfferRows === "true",
        ShowOfferRowTotals: offer.ShowOfferRowTotals === "true",
        ShowOfferRowDiscounts: offer.ShowOfferRowDiscounts === "true",
        ShowOfferRowQuantities: offer.ShowOfferRowQuantities === "true",
        ShowOfferRowPricePerUnit: offer.ShowOfferRowPricePerUnit === "true",
        YourReference: offer.YourReference,
        Headline: offer.Headline,
        Footer: offer.Footer,
        TemplateDescription: offer.TemplateDescription,
        Status: offer.Status,
        UsePaymentPlan: offer.UsePaymentPlan,
        PaymentPlanVAT: offer.PaymentPlanVAT,
        PaymentPlanSafetyPercentage: offer.PaymentPlanSafetyPercentage,
        PaymentPlanMaxTotalSafetyPercentage: offer.PaymentPlanMaxTotalSafetyPercentage,
        ShowPaymentPlanInvoiceDate: offer.ShowPaymentPlanInvoiceDate,
        ShowPaymentPlanDueDate: offer.ShowPaymentPlanDueDate,
    }

    const loadedTextBlocks = offer.text_blocks.map(row => ({
        ...row,
        Show: row.Show === "true"
    }))

    const handleChange = (e) => {
        clearSignaturePad()
        setSignatureName("")
        setAnswer(e.target.value)
    }

    const dataURItoBlob = (dataURI) => {
        var binary:any = atob(dataURI.split(',')[1]);
        var array:any = [];
        for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    };

    const submitAnswer = async () => {
        setSubmittingAnswer(true)
        
        const signature = signaturePadIsEmpty ? undefined : dataURItoBlob(signaturePad.current.toDataURL());

        const pdf = await generateOfferPdf(pages, offer.documents, offer.signature, t)

        const pdfBlob = pdf.output("blob")

        dispatch(sendOfferLinkAnswer(tenant, email, linkToken, answer, comment, signature, signatureName, pdfBlob))
            .then(()=>{
                setAnswerHasBeenSubmitted(true)
                setSubmittingAnswer(false)
            })
    }

    const clearSignaturePad = () => {
        setSignaturePadIsEmpty(true)
        if(signaturePad && signaturePad.current) {
            signaturePad.current.clear();
        }
    }

    const onDrawEnd = () => {
        const isEmpty = signaturePad.current.isEmpty()
        setSignaturePadIsEmpty(isEmpty)
    }

    const handlePagesRendered = (p) => {
        if(!isEqual(pages, p)) {   
            setPages(p)
        }
    }

    return <div style={{padding: "1em", display: "flex", justifyContent:"center", paddingBottom: "6em"}}>
        <div>
            <div>
                <h2 style={{marginBottom: 0}}>
                    {offer.Headline}
                </h2>
                <div style={{color: "grey"}}>
                    {t("SentBy")} {tenantName}
                </div>
                <br/>
                <div>
                    {t("AnswerBySelectingOneOfTheFollowingOptionsAndPressSend")}
                </div>
                <br/>
                <div>
                    <label>{t("Answer")} *</label>
                </div>
                <Radio value="accepted" onChange={handleChange} checked={answer === "accepted"}>
                    {t("Accept")}
                </Radio>
                <Radio value="rejected" onChange={handleChange} checked={answer === "rejected"}>
                    {t("Reject")}
                </Radio>
                <br/>
                <div>
                    {answer === "accepted" && <>
                        <div>
                            <label>{t("Signature")} *</label>
                        </div>
                        <div style={{color: "grey"}}>
                            <i>{t("PleaseSignHereToAccept")}</i>
                        </div>
                        <SignatureCanvas 
                            ref={signaturePad}
                            canvasProps={{width: 300, height: 220, className: 'sigCanvas'}} 
                            onEnd={onDrawEnd}
                        />
                        <div>
                            <Button disabled={signaturePadIsEmpty} onClick={clearSignaturePad}>{t("ClearSignature")}</Button>
                        </div>
                        <br/>
                        <div>
                            <FormGroup>
                                <label>{t("NameClarification")} *</label>
                                <FormControl style={{width: 300}} value={signatureName} onChange={(e:any) => {setSignatureName(e.target.value)}} />
                            </FormGroup>
                        </div>
                    </>}
                    <FormGroup>
                        <label>{t("Comment")}</label>
                        <FormControl style={{resize: "vertical", width: 300}} componentClass="textarea" value={comment} onChange={(e:any) => {setComment(e.target.value)}} />
                    </FormGroup>
                </div>
                <br/>
                <div>
                    * {t("indicatesRequiredField")}
                </div>
                <br/>
                <Button disabled={(answer === "accepted" && (signatureName === "" || signaturePadIsEmpty)) || answer === "" || submittingAnswer || sendingOfferLinkAnswer} bsStyle="primary" onClick={submitAnswer}>
                    <div style={{display:"flex", alignItems:"center"}}>
                        {submittingAnswer && <div style={{marginRight: 4}} className="loader-icon"/>}
                        <span>{submittingAnswer ? t("SendingResponse") : t("SendResponse")}</span>
                    </div>
                </Button>
            </div>
            <br/>
            <hr/>
            <br/>
            <DisplayOffer 
                companyLogo={companyLogo}
                compact={isMobileScreen}
                superCompact={isSuperSmallMobileScreen}
                documents={offer.documents} 
                Number={offer.Number} 
                Version={offer.Version}  
                rows={loadedRows} 
                offer={loadedOfferInput} 
                textBlocks={loadedTextBlocks}
                paymentPlanElements={loadedPaymentPlanElements}
                signature={null}
                onPagesRendered={handlePagesRendered}
            />
            <DocumentViewer />
        </div>
    </div>
}

export default withRouter(OfferLinkView)
